<template>
	<div class="center-main" v-loading="loading">
		<div class="title-2" style="margin-top: 50px">我的当前订阅</div>
		<div class="subscribe-box">
			<div class="subscribe-box-left">
				<div class="left-title">
					<div class="left-title-1">{{ memberPlan.name }}</div>
					<div class="left-title-2" v-if="memberPlan.expiresAt">
						有效期至
						{{ memberPlan.expiresAt && memberPlan.expiresAt.substring(0, 10) }}
					</div>
				</div>
				<div class="left-title-text">
					{{ memberPlan.expiresAt ? "享受进阶订阅时光还有" +getDistanceSpecifiedTime(memberPlan.expiresAt) +"天 " : "点击订阅发现更多精彩哦" }}
				</div>
			</div>
			<div class="subscribe-box-right" @click="showPay()">
				{{ memberPlan.expiresAt ? "续费 " : "购买" }}
			</div>
		</div>

		<div class="title-3">基本信息</div>

		<el-form ref="form" :model="form" label-width="80px" class="form">
			<el-form-item label="昵称">
				<el-input placeholder="请输入昵称" v-model="form.nickname" maxlength="10">
					<span
						slot="suffix"
						class="input-footer"
						:style="{
							color: form.nickname.length > 10 ? '#ff3465' : '#797a8e',
						}"
						>{{ form.nickname.length }}/10</span
					>
				</el-input>
			</el-form-item>
			<el-form-item label="性别">
				<el-select v-model="form.gender" placeholder="请选择">
					<el-option label="男" value="male"> </el-option>
					<el-option label="女" value="female"> </el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="个人签名">
				<div style="width: 360px;position: relative;">
					<el-input
						type="textarea"
						resize="none"
						:rows="4"
						placeholder="请输入个人签名"
						v-model="form.signature"
						maxlength="60																											"
					>
					</el-input>
					<span
						style="
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 500;
              line-height: 18px;
              color: #797a8e;
			  bottom: 10px;
			  right: 10px;
			  position: absolute;
            "
						>{{ form.signature.length }}/60</span
					>
				</div>
			</el-form-item>
			<!-- <el-form-item label="昵称颜色">
        <el-color-picker v-model="form.color"></el-color-picker>
      </el-form-item> -->

			<el-form-item>
				<div class="form-button" @click="setUserInfo()">保存</div>
			</el-form-item>
		</el-form>

		<div class="title-3">修改手机号</div>

		<el-form ref="form" :model="form" label-width="80px" class="form">
			<el-form-item label="原手机号">
				<el-input placeholder="请输入手机号" v-model="form.mobile" disabled> </el-input>
			</el-form-item>
			<el-form-item label="新手机号">
				<el-input placeholder="请输入手机号" v-model="form.new_phoneNumber"> </el-input>
			</el-form-item>
			<el-form-item label="验证码">
				<el-input placeholder="请输入验证码" v-model="form.code_1">
					<el-button
						type="text"
						slot="suffix"
						style="color: #ff3465; margin-right: 12px"
						:disabled="phoneCodeText != '发送验证码'"
						@click="getCodeForPhone()"
						>{{ phoneCodeText }}</el-button
					>
				</el-input>
				<div class="tip">验证码将发送至原手机号，请注意查收</div>
			</el-form-item>
			<el-form-item>
				<div class="form-button" @click="editPhone()">提交</div>
			</el-form-item>
		</el-form>

		<div class="title-3">密码安全</div>

		<el-form ref="form" :model="form" label-width="80px" class="form">
			<el-form-item label="验证码">
				<el-input placeholder="请输入验证码" v-model="form.code_2">
					<el-button
						type="text"
						slot="suffix"
						style="color: #ff3465; margin-right: 12px"
						@click="getCodeForPassword()"
						:disabled="passwordCodeText != '发送验证码'"
						>{{ passwordCodeText }}</el-button
					>
				</el-input>
				<div class="tip">验证码将发送至原手机号，请注意查收</div>
			</el-form-item>
			<el-form-item label="新密码">
				<el-input type="password" placeholder="请输入密码" v-model="form.password"> </el-input>
			</el-form-item>
			<el-form-item>
				<div class="form-button" @click="editPassword()">提交</div>
			</el-form-item>
		</el-form>

		<div class="avatar-setting">
			<div class="avatar-setting-img" style="border: 1px solid white">
				<img :src="getImg(form.avatar, 70,70, null)" alt="" />
			</div>
			<el-upload
				class="upload-demo"
				ref="upload"
				:action="api.uploadApi"
				:on-preview="handlePreview"
				:before-upload="beforeAvatarUpload"
				:on-success="handleAvatarSuccess"
				:headers="headers"
				:limit="1"
				:file-list="fileList"
				:show-file-list="false"
			>
				<div class="avatar-setting-button">更换</div>
			</el-upload>
		</div>

		<el-dialog
			:visible="dialogVisible"
			width="800px"
			:close-on-click-modal="true"
			:show-close="false"
			class="pay-dialog"
		>
			<div slot="title" class="pay-title" >
				<div class="pay-title-left">
					<div class="pay-title-left-avatar">
						<img :src="getImg(form.avatar, 100,100, null)" alt="" />
					</div>
					<div class="pay-title-left-title">
						<div class="pay-title-left-title-1">{{ userinfo.nickname }}</div>
						<div class="pay-title-left-title-2">
							<span style="margin-right: 10px">{{ memberPlan.name }}</span>
							<span v-if="memberPlan.expiresAt"
								>有效期至：{{ memberPlan.expiresAt.substring(0, 10) }}</span
							>
						</div>
					</div>
				</div>
				<div class="pay-title-right">
					<i class="el-icon-close" style="color: white" @click="dialogVisible = false;
				isPayStatus = false;"></i>
				</div>
			</div>
			<div class="pay-content" v-loading="payLoading">
				<div v-if="isPayStatus" style="text-align: center;">
					<div style="width: 100%;text-align: center;margin: 20px 0">
						<img :src="noPathImage" alt="" style="width: 157px;height: 157px">
					</div>
					<div style="height: 40px;
font-size: 28px;
font-family: PingFang SC;
font-weight: 500;
line-height: 40px;
color: #FF3465;">
						感谢您的订阅！
					</div>
					<div style="text-align: center;margin: 20px 0">
						<div>通常支付成功之后，权益为实时到账，</div>
						<div>若出现任何问题，请及时与我们联系。</div>
					</div>

					<div style="text-align: center;">
						<div style="width: 80px;margin:auto;
    height: 35px;
    background: #ff3465;
    opacity: 1;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 20px;
    color: #ffffff;
    margin-top: 120px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;" @click="dialogVisible = false">好的</div>
					</div>

				</div>
				<el-tabs v-if="!isPayStatus" v-model="activeName2"  @tab-click="handleClick">
					<el-tab-pane
						:key="index"
						v-for="(item, index) in plansList"
						:label="item.name"
						:name="index"
					>
						<div class="pay-list">
							<div
								class="pay-list-item"
								v-for="(it, idx) in item.price"
								:key="idx"
								:class="{
									'pay-list-item-active': it.type == currentPlay.type,
								}"
								@click="selectPayPlan(it, index)"
							>
								<div class="pay-list-item-title">{{ it.name }}</div>
								<div class="pay-list-item-cost">
									<span>{{ it.reducedPrice }}</span
									>元
								</div>
								<div class="pay-list-item-old">
									<span>￥{{ it.originalPrice }}</span>
								</div>
							</div>
						</div>
						<div class="pay-text-1">{{ currentPlay.intro }}</div>
						<div class="pay-value">
							<div class="pay-value-left">
								<div class="pay-value-left-title">特权</div>
								<div class="pay-value-left-content">
									<div
										class="pay-value-left-content-title"
										v-html="item.privilegeIntro"></div>
								</div>
							</div>
							<div class="pay-value-right">
								<div class="pay-value-right-button">
									<div v-if="payConfig.weixin === 1"
										:style="{
											background:
												selectedPayTypeNoraml == 'wechat' ? 'rgba(248, 248, 248, 1)' : 'white',
										}"
										@click="selectPayCode('wechat', index===0?true:false, index)"
									>
										<span>微信支付</span>
									</div>
									<div v-if="payConfig.alipay === 1"
										:style="{
											background:
												selectedPayTypeNoraml == 'alipay' ? 'rgba(248, 248, 248, 1)' : 'white',
										}"
										@click="selectPayCode('alipay', index===0?true:false, index)"
									>
										<span>支付宝</span>
										<img :src="alipayImage" alt="" />

									</div>
								</div>
								<div class="pay-value-right-content">
									<div v-if="payConfig.weixin === 1"
										class="pay-value-right-content-wechat"
										v-show="selectedPayTypeNoraml == 'wechat'"
									>
										<div class="wechat-left">
											<div class="vrcode" v-loading="vrCodeLoading">
												<div :id="`qrcode-noraml-wechat-${index}`" :ref="`qrcode-noraml-wechat-${index}`">

												</div>
											</div>
										</div>
										<div class="wechat-right">
											<div class="wechat-right-name">{{item.name}} · {{ currentPlay.name }}</div>
											<div class="wechat-right-cost">
												<span>{{ currentPlay.reducedPrice }}</span
												>元
											</div>
											<el-button
												style="color: rgba(255, 52, 101, 1); margin-top: 20px"
												type="text"
											>《订阅服务协议》</el-button
											>
										</div>
									</div>
									<div v-if="payConfig.alipay === 1"
										class="pay-value-right-content-wechat"
										v-show="selectedPayTypeNoraml == 'alipay'"
									>
										<div class="wechat-left">
											<div class="vrcode" v-loading="vrCodeLoading">
												<div :id="`qrcode-noraml-alipay-${index}`" :ref="`qrcode-noraml-alipay-${index}`">

												</div>
											</div>
										</div>
										<div class="wechat-right">
											<div class="wechat-right-name">{{item.name}} · {{ currentPlay.name }}</div>
											<div class="wechat-right-cost">
												<span>{{ currentPlay.reducedPrice }}</span
												>元
											</div>
											<el-button
												style="color: rgba(255, 52, 101, 1); margin-top: 20px"
												type="text"
											>《订阅服务协议》</el-button
											>
										</div>

									</div>
								</div>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import QRCode from "qrcodejs2"
export default {
	name: "center-main",
	data() {
		return {
			noPathImage: require("@assets/NoPath.png"),
			payBackImage: require("@assets/ic_payment_head.png"),
			headers: {},
			avatarImage: require("@assets/avatar2.png"),
			alipayImage: require("@assets/center/alipay.png"),
			form: {
				nickname: "",
				gender: "",
				signature: "",

				mobile: "",
				new_phoneNumber: "",
				code_1: "",

				code_2: "",
				password: "",
				avatar: "",
			},
			loading: false,
			dialogVisible: false,
			color: " #121330",
			payList: [
				{
					name: "月度",
					cost: "9.89",
					oldCost: "10.00",
				},
				{
					name: "季度",
					cost: "19.89",
					oldCost: "10.00",
				},
				{
					name: "年度",
					cost: "99.89",
					oldCost: "10.00",
				},
				{
					name: "两年",
					cost: "199.89",
					oldCost: "10.00",
				},
			],

			selectedPayNoraml: "月度",
			selectedPayVip: "月度",

			selectedPayTypeNoraml: "wechat",

			fileList: [],

			memberPlan: {
				name: "",
				expiresAt: "",
			},
			vipData: {},
			svipData: {},
			userinfo: {},
			payLoading: false,
			vrCodeLoading: false,

			currentPlay: {},
			currentSvipPlay: {},

			countObject: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"],
			activeName: "vip",

			phoneCodeText: "发送验证码",
			phoneTimer: null,
			phoneTimerNumber: 60,

			passwordCodeText: "发送验证码",
			passwordTimer: null,
			passwordTimerNumber: 60,

			wechatPayObject: {},
			alipayPayObject: {},
			plansList: [],
			activeName2: '0',
			currentOrderNo: null, // 当前要查询的订单号
			interavalTemp: null,
			isPayStatus: false, // 支付状态
			payConfig: {
				weixin: 1,
				alipay: 1
			}
		}
	},
	watch: {
		dialogVisible: function(val) {
			console.log('dialogVisible',val)
			// 关闭时，清除定时器
				clearInterval(this.interavalTemp)
				this.interavalTemp = null

		},
	},
	methods: {
		getDistanceSpecifiedTime(dateTime) {
			// 指定日期和时间
			var EndTime = new Date(dateTime)
			// 当前系统时间
			var NowTime = new Date()
			var t = EndTime.getTime() - NowTime.getTime()
			var d = Math.floor(t / 1000 / 60 / 60 / 24)
			return d
		},
		handleClick(tab, event) {

			this.currentPlay = this.plansList[Number(tab.name)].price[0]

			this.selectPayPlan(this.plansList[Number(tab.name)].price[0])
			this.selectPayCode("wechat", true, Number(tab.name))

		},
		async init() {
			const result = await this.api.getUserInfo()
			if (result.code == 1) {
				this.form.nickname = result.data.userInfo.nickname
				this.form.gender = result.data.userInfo.gender
				this.form.signature = result.data.userInfo.signature
				this.form.mobile = result.data.userInfo.mobile
				this.form.avatar = result.data.userInfo.avatar
				this.memberPlan = result.data.memberPlan
				this.userinfo = result.data.userInfo
			}
			console.log(result)
		},
		async getCodeForPassword() {
			if (!this.form.mobile) {
				this.$message.warning("请输入完整手机号获取验证码。")
				return
			}
			if (this.passwordTimerNumber != 60) return
			const result = await this.api.sendCodeForForget({
				mobile: this.form.mobile,
			})
			if (result.code === 1) {
				this.$message.success(result.msg)

				this.passwordTimer = setInterval(() => {
					if (this.passwordTimerNumber < 0) {
						this.passwordCodeText = "发送验证码"
						this.passwordTimerNumber = 60
						clearInterval(this.passwordTimer)
					} else {
						this.passwordCodeText = String(this.passwordTimerNumber) + "秒后获取"
						this.passwordTimerNumber = this.passwordTimerNumber - 1
					}
				}, 1000)
			} else {
				this.$message.error(result.msg)
			}
		},
		async getCodeForPhone() {
			if (!this.form.new_phoneNumber) {
				this.$message.warning("请输入完整手机号获取验证码。")
				return
			}
			if (!/^[1][3,4,5,7,8][0-9]{9}$/.test(this.form.mobile)) {
				this.$message.warning("原手机号有误，请重填")
				return
			}
			if (!/^[1][3,4,5,7,8][0-9]{9}$/.test(this.form.new_phoneNumber)) {
				this.$message.warning("新手机号有误，请重填")
				return
			}
			if (this.phoneTimerNumber != 60) return
			const result = await this.api.getEditPhoneCode({
				mobile: this.form.mobile,
			})
			if (result.code === 1) {
				this.$message.success(result.msg)

				this.phoneTimer = setInterval(() => {
					if (this.phoneTimerNumber < 0) {
						this.phoneCodeText = "发送验证码"
						this.phoneTimerNumber = 60
						clearInterval(this.phoneTimer)
					} else {
						this.phoneCodeText = String(this.phoneTimerNumber) + "秒后获取"
						this.phoneTimerNumber = this.phoneTimerNumber - 1
					}
				}, 1000)
			} else {
				this.$message.error(result.msg)
			}
		},
		async setUserInfo() {
			const { nickname, avatar, signature, gender } = this.form
			if (nickname.length > 10) {
				this.$message.warning("昵称超过长度，请修改后保存。")
				return
			}
			const result = await this.api.setUserInfo({
				nickname,
				avatar,
				signature,
				gender,
			})
			console.log(result.data)
			if (result.code == 1) {
				this.$store.commit("setUserData", {
					...result.data,
					token: this.$store.state.userData.token,
				})
				this.$message.success("修改成功")
			} else {
				this.$message.error(result.msg)
			}
			console.log(result)
		},
		handlePreview(file) {
			console.log(file)
		},
		beforeAvatarUpload(file) {
			console.log(file)

			const isGo = ["image/jpg", "image/jpeg", "image/png"].includes(file.type)
			if (!isGo) this.$message.error("请上传图片作为头像。")
			return isGo
		},
		async handleAvatarSuccess(res, file) {
			console.log(res, file)
			this.form.avatar = res.data.path
			this.$refs.upload.clearFiles()
			const result = await this.setUserInfo()
			console.log(result)
		},
		async showPay() {
			this.isPayStatus = false
			this.activeName2 = '0'
			this.dialogVisible = true
			this.payLoading = true
			const result = await this.api.getPlan()
			console.log(result)
			if (result.code == 1) {
				this.plansList = result.data.list

				this.vipData = result.data.list[0]
				this.svipData = result.data.list[1]
				// 默认选择第一个
				this.selectPayPlan(this.plansList[0].price[0])
				if(this.payConfig.weixin === 1) {
					this.selectPayCode("wechat", true, 0)
				} else {
					this.selectPayCode("alipay", true, 0)
				}
				// this.selectPaySvipCode("wechat", true)
				// this.selectPaySvipPlan(this.svipData.price[0])
				console.log(this.svipData)
			}

			this.payLoading = false
		},
		selectPayPlan(item, index) {
			console.log(item)
			this.currentPlay = item
			this.getVrCode(index)
		},
		selectPaySvipPlan(item) {
			console.log(item)
			this.currentSvipPlay = item
			this.getVrCodeVip()
		},
		async getVrCodeVip() {
			console.log(this.activeName)
			if (this.selectedPayVip == "wechat") {
				this.vrCodeLoading = true
				const result = await this.getWechat({
					planId: this.currentSvipPlay.planId,
					period: this.currentSvipPlay.period,
					type: "NATIVE",
				})
				console.log(result)
				this.initCode("vip", result.data, "wechat")
				this.vrCodeLoading = false
			} else {
				this.vrCodeLoading = true
				const result = await this.getAlipay({
					planId: this.currentSvipPlay.planId,
					period: this.currentSvipPlay.period,
					type: "NATIVE",
				})
				console.log(result)
				this.initCode("vip", result.data, "alipay")
				this.vrCodeLoading = false
			}
		},

		async getVrCode(index) {
			console.log(this.activeName)
			if (this.selectedPayTypeNoraml == "wechat") {
				this.vrCodeLoading = true
				const result = await this.getWechat({
					planId: this.currentPlay.planId,
					period: this.currentPlay.period,
					type: "NATIVE",
				})
				console.log(result)
				this.initCode("normal", result.data, "wechat", index)
				this.vrCodeLoading = false
				this.currentOrderNo = result.data.orderNo

				this.getOrderStatus()
			} else {
				this.vrCodeLoading = true
				const result = await this.getAlipay({
					planId: this.currentPlay.planId,
					period: this.currentPlay.period,
					type: "NATIVE",
				})
				console.log(result)
				this.initCode("normal", result.data, "alipay", index)
				this.vrCodeLoading = false
				this.currentOrderNo = result.data.orderNo
				this.getOrderStatus()
			}

		},
		getOrderStatus () {
			// this.interavalTemp = null
			clearInterval(this.interavalTemp)
			const _that = this
			this.interavalTemp= setInterval(function(){
				_that.api.getOrderStatus(_that.currentOrderNo).then(res=> {
					// 执行支付成功逻辑
					console.log(res)

					if(res.code === 1) {
						if(res.data.status === 'success') {

							_that.isPayStatus = true
							clearInterval(this.interavalTemp)
							this.interavalTemp = null
						}
					}
				})
			}, 3000);
		},

		initCode(type, data, payType, index) {
			console.log(type, data, payType)
			if (type == "normal") {

				document.getElementById(`qrcode-noraml-${payType}-${index}`).innerHTML = ''

				let qrcode = new QRCode(document.getElementById(`qrcode-noraml-${payType}-${index}`), {
					width: 120,
					height: 120,
					text: data.codeUrl, // 二维码地址
				})

			} else {
				document.querySelector("#qrcode-vip-" + payType+ '-' +index).innerHTML = ""
				let qrcode = new QRCode("qrcode-vip-" + payType+ '-' +index, {
					width: 120,
					height: 120,
					text: data.codeUrl, // 二维码地址
				})
			}
			// document.querySelector(".qrcode-noraml-" + payType).innerHTML = ""
			// let qrcode = new QRCode("qrcode-noraml-" + payType, {
			// 	width: 120,
			// 	height: 120,
			// 	text: data.codeUrl, // 二维码地址
			// })
		},
		async getWechat(httpObj) {
			console.log(this.currentPlay)
			const result = await this.api.getWechat(httpObj)
			return result
		},
		async getAlipay(httpObj) {
			const result = await this.api.getAlipay(httpObj)
			if (result.code === 1) {
				this.alipayPayObject = result.data
			}
			return result
		},
		selectPayCode(type, isFirst, index) {
			this.selectedPayTypeNoraml = type

				this.getVrCode(index)
		},
		selectPaySvipCode(type, isFirst) {
			this.selectedPayVip = type
			if (!isFirst) {
				this.getVrCodeVip()
			}
		},

		async editPhone() {
			if (!this.form.new_phoneNumber) {
				this.$message.warning("请输入新的手机号。")
				return
			}
			if (!this.form.code_1) {
				this.$message.warning("请输入验证码后修改。")
				return
			}
			if (!/^[1][3,4,5,7,8][0-9]{9}$/.test(this.form.new_phoneNumber)) {
				this.$message.warning("新手机号有误，请重填")
				return
			}
			const result = await this.api.setPhone({
				code: this.form.code_1,
				mobile: this.form.new_phoneNumber,
			})
			if (result.code === 1) {
				this.$message.success(result.msg)
			} else {
				this.$message.error(result.msg)
			}
			console.log(result)
		},
		async editPassword() {
			if (!this.form.code_2) {
				this.$message.warning("请输入验证码后修改。")
				return
			}
			if (!this.form.password) {
				this.$message.warning("请输入新密码后修改。")
				return
			}
			const result = await this.api.editPassword({
				mobile: this.form.mobile,
				code: this.form.code_2,
				newPassword: this.form.password,
			})
			if (result.code === 1) {
				this.$message.success(result.msg)
			} else {
				this.$message.error(result.msg)
			}
			console.log(result)
		},
	},
	mounted() {
		const config  = localStorage.getItem('BIYOUCONFIG')
		if(config !== null) {
			console.log('JSON.parse(config).data.pay', JSON.parse(JSON.parse(config).data).pay)
			this.payConfig = JSON.parse(JSON.parse(config).data).pay

		} else {
			console.log(config)
		}
		this.headers = { platform: 'web',
			Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userData')).token}
			console.log(JSON.parse(localStorage.getItem('userData')).token)

			this.init()


	},
	activated() {
		this.init()
	},
}
</script>

<style lang="scss">
.title-2 {
	font-size: 18px;
	font-family: PingFang SC;
	font-weight: 600;
	line-height: 25px;
	color: #ff3465;
	// margin-top: 50px;
}
.subscribe-box {
	width: 550px;
	height: 110px;
	background: #ff3465;
	opacity: 1;
	border-radius: 10px;
	margin-top: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 30px 0 50px;
	.subscribe-box-left {
		.left-title {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			.left-title-1 {
				font-size: 25px;
				font-family: PingFang SC;
				font-weight: 600;
				line-height: 36px;
				color: #ffffff;
				position: relative;
				top: 4px;
			}
			.left-title-2 {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 500;
				line-height: 20px;
				color: rgba(255, 255, 255, 0.6);
				margin-left: 10px;
			}
		}
		.left-title-text {
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: 500;
			line-height: 25px;
			color: #ffffff;
			margin-top: 10px;
		}
	}
	.subscribe-box-right {
		width: 160px;
		height: 60px;
		background: linear-gradient(180deg, #ffffff 0%, #ffbece 100%);
		box-shadow: 0px 5px 10px rgba(193, 0, 74, 0.2);
		opacity: 1;
		border-radius: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 25px;
		color: #ff3465;
		cursor: pointer;
		user-select: none;
	}
}
.title-3 {
	font-size: 18px;
	font-family: PingFang SC;
	font-weight: 600;
	line-height: 25px;
	color: #797a8e;
	margin-top: 50px;
	margin-bottom: 20px;
}

.form {
	.el-input,
	.el-textarea {
		width: 360px !important;
	}
	.el-input__inner,
	.el-textarea__inner {
		background-color: rgba(121, 122, 142, 0.1);
		color: #b6b7c3;
		border: none !important;
	}
	.el-form-item__label {
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 500;
		color: rgba(121, 122, 142, 1);
	}
	.el-input.is-disabled .el-input__inner {
		background-color: rgba(121, 122, 142, 0.1);
		color: #b6b7c3;
		border: none !important;
	}
}
.input-footer {
	font-size: 13px;
	font-family: PingFang SC;
	font-weight: 500;
	line-height: 18px;
	color: #797a8e;
	margin-right: 10px;
}
.form-button {
	width: 130px;
	height: 50px;
	background: #ff3465;
	opacity: 1;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 16px;
	font-family: PingFang SC;
	font-weight: 400;
	line-height: 22px;
	color: #ffffff;
	user-select: none;
	cursor: pointer;
}

.pay-dialog {
	.el-dialog {
		overflow: hidden;
	}
	.el-dialog__header {
		padding: 0 !important;
	}
	.pay-title {
		background:url('~@assets/ic_payment_head.png') no-repeat left top;
		/*background: rgba(121, 122, 142, 1);*/
		height: 120px;
		padding: 0 20px 0 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		user-select: none;
		.pay-title-left {
			display: flex;
			justify-content: center;
			align-items: center;
			.pay-title-left-avatar {
				width: 80px;
				height: 80px;
				border-radius: 50%;
				overflow: hidden;
				margin-right: 20px;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.pay-title-left-title {
				margin-top: 5px;
				.pay-title-left-title-1 {
					font-size: 22px;
					font-family: PingFang SC;
					font-weight: 600;
					line-height: 30px;
					color: #ffffff;
				}
				.pay-title-left-title-2 {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 500;
					line-height: 20px;
					color: #ffffff;
				}
			}
		}
		.pay-title-right {
			i {
				cursor: pointer;
			}
		}
	}
	.pay-list {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.pay-list-item {
			width: 158px;
			height: 140px;
			margin: 0 6px;
			border: 2px solid #b6b7c3;
			opacity: 1;
			border-radius: 5px;
			padding: 20px;
			user-select: none;
			cursor: pointer;
			.pay-list-item-title {
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 500;
				line-height: 28px;
				color: #121330;
			}
			.pay-list-item-cost {
				font-size: 15px;
				font-family: PingFang SC;
				font-weight: 500;
				line-height: 21px;
				color: #ff3465;
				margin-top: 20px;
				span {
					font-size: 35px;
				}
			}
			.pay-list-item-old {
				font-size: 15px;
				font-family: PingFang SC;
				font-weight: 500;
				line-height: 21px;
				color: #797a8e;
				text-decoration: line-through;
				margin-top: 8px;
			}
		}
		.pay-list-item-active {
			border: 2px solid #ff3465;
		}
	}
	.pay-text-1 {
		background: rgba(255, 52, 101, 0.1);

		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #ff3465;
		padding: 16px 20px;
		margin: 0 8px;
		margin-top: 15px;

		border-radius: 5px;
	}
	.pay-value {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		.pay-value-left {
			width: 50%;
			padding-left: 20px;
			.pay-value-left-title {
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: 600;
				line-height: 25px;
				color: #ff3465;
				margin-bottom: 24px;
			}
			.pay-value-left-content-title {
				margin-bottom: 14px;
				.pay-value-left-content-title-1 {
					font-size: 15px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 21px;
					color: #797a8e;
				}
				.pay-value-left-content-title-2 {
					font-size: 18px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 25px;
					color: #ff3465;
					margin-left: 10px;
				}
			}
		}
		.pay-value-right {
			width: 50%;
			.pay-value-right-button {
				display: flex;
				justify-content: center;
				align-items: center;
				div {
					height: 45px;
					width: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					background: rgba(248, 248, 248, 1);
					user-select: none;
					cursor: pointer;

					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 22px;
					color: #121330;
					img {
						width: 58px;
						height: 24px;
					}
				}
			}
			.pay-value-right-content {
				height: 160px;
				background: rgba(248, 248, 248, 1);
				.pay-value-right-content-wechat {
					height: calc(100% - 40px);
					width: calc(100% - 40px);
					padding: 20px;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					.wechat-left {
						.vrcode {
							width: 120px;
							height: 120px;
							background: #ccc;
						}
					}
					.wechat-right {
						margin-left: 15px;
						height: 100%;
						.wechat-right-name {
							font-size: 16px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 22px;
							color: #121330;
							margin-bottom: 15px;
						}
						.wechat-right-cost {
							font-size: 15px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 21px;
							color: #ff3465;
							span {
								font-size: 35px;
							}
						}
					}
				}
				.pay-value-right-content-alipay {
					height: calc(100% - 40px);
					width: calc(100% - 40px);
					padding: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					.alipay-left {
						height: 100%;
						.alipay-left-name {
							font-size: 16px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 22px;
							color: #121330;
							margin-bottom: 15px;
						}
						.alipay-left-cost {
							font-size: 15px;
							font-family: PingFang SC;
							font-weight: 500;
							line-height: 21px;
							color: #ff3465;
							span {
								font-size: 35px;
							}
						}
					}
					.alipay-right {
						margin-left: 15px;
						width: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						.alipay-right-button {
							width: 120px;
							height: 40px;
							background: #ff3465;
							opacity: 1;
							border-radius: 5px;

							font-size: 16px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 22px;
							color: #ffffff;
							display: flex;
							justify-content: center;
							align-items: center;
							user-select: none;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
	.el-tabs__nav {
		width: 100%;
	}
	.el-tabs__item {
		width: 50%;
	}
}

@media screen and (max-width: 1680px) {
	.subscribe-box {
		.subscribe-box-left {
			.left-title {
				.left-title-1 {
					font-size: 20px;
				}
				.left-title-2 {
					font-size: 12px;
				}
			}
			.left-title-text {
				font-size: 16px;
			}
		}
	}
}
</style>
